import menu1 from "./menu1.jpg";
import menu2 from "./menu2.jpg";
import menu3 from "./menu3.jpg";
import menu4 from "./menu4.jpg";
import menu5 from "./menu5.jpg";
import menu6 from "./menu6.jpg";
import menu7 from "./menu7.jpg";
import menu8 from "./menu8.jpg";
import menu9 from "./menu9.jpg";
import menu10 from "./menu10.jpg";
import menu11 from "./menu11.jpg";
import menu12 from "./menu12.jpg";
import menu13 from "./menu13.jpg";
import menu14 from "./menu14.jpg";

// Returning them in an array
const menuImages = [
  menu1,
  menu2,
  menu3,
  menu4,
  menu5,
  menu6,
  menu7,
  menu8,
  menu9,
  menu10,
  menu11,
  menu12,
  menu13,
  menu14
];

export default menuImages;
