import menu1 from "./menu1.jpeg";
import menu2 from "./menu2.jpeg";
import menu3 from "./menu3.jpeg";

// Returning them in an array
const menuImages = [
  menu1,
  menu2,
  menu3
];

export default menuImages;
