import category1 from "./category-1.jpeg";
import category2 from "./category-2.jpeg";
import category3 from "./category-3.jpeg";
import category4 from "./category-4.jpeg";
import category5 from "./category-5.jpeg";
import category6 from "./category-6.jpeg";
import category7 from "./category-7.jpeg";
import category8 from "./category-8.jpeg";
import category9 from "./category-9.jpeg";
import category10 from "./category-10.jpeg";
import category11 from "./category-11.jpeg";
import category12 from "./category-12.jpeg";

// Returning them in an array
const menuImages = [
  category1,
  category2,
  category3,
  category4,
  category5,
  category6,
  category7,
  category8,
  category9,
  category10,
  category11,
  category12,
];

export default menuImages;
