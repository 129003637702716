import menu1 from "./menu1.jpg";
import menu2 from "./menu2.jpg";
import menu3 from "./menu3.jpg"

// Returning them in an array
const menuImages = [
  menu1,
  menu2,
  menu3,
];

export default menuImages;
