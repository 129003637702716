import menu1 from "./menu1.png";
import menu2 from "./menu2.png";
import menu3 from "./menu3.png";
import menu4 from "./menu4.png";
import menu5 from "./menu5.png";

// Returning them in an array
const menuImages = [
  menu1,
  menu2,
  menu3,
  menu4,
  menu5,
];

export default menuImages;
