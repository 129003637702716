import offer1 from "./offer-1.jpeg";
import offer2 from "./offer-2.jpeg";
import offer3 from "./offer-3.jpeg";
import offer4 from "./offer-4.jpg";
import offer5 from "./offer-5.jpg";
import offer6 from "./offer-6.jpg";
import offer7 from "./offer-7.jpg";
import offer8 from "./offer-8.jpg";
import offer9 from "./offer-9.jpg";
import offer10 from "./offer-10.jpg";
import offer11 from "./offer-11.jpg";
import offer12 from "./offer-12.jpg";

// Returning them in an array
const menuImages = [
  offer1,
  offer2,
  offer3,
  offer4,
  offer5,
  offer6,
  offer7,
  offer8,
  offer9,
  offer10,
  offer11,
  offer12,
];

export default menuImages;
